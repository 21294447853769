<template>
	<div>
		<CRow>
			<div class="col-md-12">
				<CTabs style="background-color: #ffffff">
					<CTab title="PO Items" active>
						<CRow>
							<CCol md="12" class="m-2">
								<table class="table table-sm table-hover">
									<thead>
										<tr>
											<th scope="col">#</th>
											<th scope="col">Kode</th>
											<th scope="col">Product</th>
											<th scope="col">Size</th>
											<th scope="col">Harga Beli</th>
											<th scope="col">Harga Jual</th>
											<th scope="col">Jumlah</th>
										</tr>
									</thead>
									<tbody>
										<tr :key="i" v-for="(p, i) in rowData.purchaseItems">
											<td>{{ i + 1 }}</td>
											<td>{{ p.kode }}</td>
											<td>{{ p.product.nama }}</td>
											<td>
												{{
													p.size +
													" / " +
													size[p.size].nama +
													" / " +
													size[p.size].alias
												}}
											</td>
											<td class="text-right">
												{{ p.hargaBeli.toLocaleString() }}
											</td>
											<td class="text-right">
												{{ p.hargaJual.toLocaleString() }}
											</td>
											<td>{{ p.jumlah }}</td>
										</tr>
									</tbody>
								</table>
							</CCol>
						</CRow>
					</CTab>
					<CTab title="Order">
						2. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
						eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
						ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
						aliquip ex ea commodo consequat. Duis aute irure dolor in
						reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
						pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
						culpa qui officia deserunt mollit anim id est laborum.
					</CTab>
				</CTabs>
			</div>
		</CRow>
	</div>
</template>

<script>
import { size } from "@/plugins/utils";
export default {
	components: {},
	data() {
		return {
			size,
			sesuais: [],
		};
	},
	props: {
		rowData: {
			type: Object,
			required: true,
		},
		rowIndex: {
			type: Number,
		},
	},
};
</script>
