<template>
	<div>
		<CRow>
			<CCol>
				<CCard>
					<CCardHeader> PO List </CCardHeader>
					<CCardBody>
						<CRow>
							<CCol md="4">
								<CInput
									label="Filter"
									placeholder="Enter Filter"
									v-model="moreParams.filter"
								/>
							</CCol>
							<CCol md="4">
								<div role="group" class="form-group">
									<CButton
										@click="reset"
										id="btn"
										color="primary"
										style="margin-top: 16px"
									>
										<CIcon name="cil-reload" />
									</CButton>
									<CButton
										@click="add"
										id="btn"
										color="success"
										style="margin-top: 16px; margin-left: 5px"
									>
										<CIcon name="cil-plus" />
									</CButton>
								</div>
							</CCol>
						</CRow>
						<div class="table-responsive" style="max-height: 900px">
							<vuetable
								v-show="!loadingTable"
								ref="vuetable"
								:api-url="uri"
								:fields="fields"
								:per-page="15"
								pagination-path="pagination"
								data-path="mydata"
								:css="cssVuetable.table"
								:transform="transformData"
								:append-params="moreParams"
								:http-options="httpoption"
								:detail-row-component="detailRow"
								@vuetable:pagination-data="onPaginationData"
								@vuetable:loading="onLoadingTable"
								@vuetable:load-success="onLoadSuccessTable"
							>
								<template slot="slot-actions" slot-scope="props">
									<CButton
										@click="showDetail(props.rowData.id)"
										color="secondary"
										size="sm"
										style="margin-left: 0px"
										class="py-0"
									>
										<CIcon name="cil-chevron-double-down" />
									</CButton>
								</template>
							</vuetable>
							<div v-show="loadingTable" class="text-center">
								<ellipsis-loader :color="'#54f1d2'" />
							</div>
						</div>
						<vuetable-pagination
							ref="pagination"
							:css="cssVuetable.pagination"
							@vuetable-pagination:change-page="onChangePage"
						>
						</vuetable-pagination>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import { basuri, debounce } from "@/plugins/utils";
import PurchaseDetail from "./PurchaseDetail";

export default {
	components: {
		PurchaseDetail,
	},
	data() {
		return {
			uri: basuri + "po",
			moreParams: { filter: null, t_: Date.now() },
			selectedId: null,
			loading: false,
			modal: false,
			detailRow: PurchaseDetail,
			frm: {
				nama: "",
				harga: 0,
				hargaBeli: 0,
				lokasi: 0,
				produksi: "import",
			},

			fields: [
				{
					name: "kode",
					title: "Kode",
					sortField: "kode",
				},
				{
					name: "biaya",
					title: "Biaya",
					sortField: "biaya",
					dataClass: "text-right",
					formatter(value) {
						return value.toLocaleString();
					},
				},
				{
					name: "hargaBeli",
					title: "Harga Beli",
					sortField: "hargaBeli",
					dataClass: "text-right",
					formatter(value) {
						return value.toLocaleString();
					},
				},
				{
					name: "hargaJual",
					title: "Harga Jual",
					sortField: "hargaJual",
					dataClass: "text-right",
					formatter(value) {
						return value.toLocaleString();
					},
				},
				{
					name: "pieces",
					title: "Pieces",
					sortField: "pieces",
					dataClass: "text-right",
					formatter(value) {
						return value.toLocaleString();
					},
				},
				{
					name: "jenis",
					title: "Jenis",
					sortField: "jenis",
				},
				{
					name: "date",
					title: "Tanggal",
					sortField: "date",
				},
				{
					name: "slot-actions",
					title: "",
				},
			],
		};
	},
	watch: {
		"moreParams.filter": debounce(function (val) {
			if (val.length > 3 || val.length == 0) this.$refs.vuetable.refresh();
		}, 1000),
	},
	methods: {
		reset() {
			this.moreParams = { filter: null, t_: Date.now() };
			//this.$refs.vuetable.refresh();
		},
		add() {
			this.$router.push({ name: "PO Form" });
		},
	},
};
</script>
